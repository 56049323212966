import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'animate.css'
import '@vdt/component/dist/main.css'
import '@vdt/component/dist/index.css'
import '@vdt/login/dist/main.css'
import '@vdt/translation/dist/main.css'
import '@vdt/user-management/dist/main.css'
import '@vdt/campagne/dist/main.css'
import './index.css';

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
