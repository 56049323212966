import { AppRouteProps } from "@vdt/component";
import React from "react";

const CampagneCreateContainer = React.lazy(() => import('./containers/CampagneCreateContainer'))

export const CampagneCreateRoutes: Array<AppRouteProps> = [
    {
        path: '-1',
        element: <CampagneCreateContainer/>,
        title: "Vdt.Campagne.Create.Title"
    }
]