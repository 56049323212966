import {
    GenericSuspense,
    useRuntimeEnv,
    useTranslationRoute,
} from "@vdt/component";
import { Outlet, useNavigate } from "react-router-dom";
import { useEffect } from "react";

const OrganisationAccessLayer = () => {
    const navigate = useNavigate();
    const tr = useTranslationRoute();

    const { runtimeEnv } = useRuntimeEnv();
    const isAuthenticated = runtimeEnv?.authenticated;
    const hasOrganisation = runtimeEnv?.organisation !== null;

    useEffect(() => {
        if (isAuthenticated && !hasOrganisation) {
            navigate(tr("auth/organisation"));
        }
    }, []);

    return (
        <>
            <GenericSuspense>
                <Outlet />
            </GenericSuspense>
        </>
    );
};

export default OrganisationAccessLayer;
