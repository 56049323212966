import React from 'react';
import { AppRoot, MainLogo, useCdnAssets } from '@vdt/component'
import { AppRoutes } from './page/AppRoutes'
import './App.css'

const App: React.FC = () => {

    const cdn = useCdnAssets()

    return (
        <AppRoot 
            routes={AppRoutes} 
            protectedRoutes="*"
            unProtectedRoutes={UNPROTECTED_ROUTES}
            loginPath={process.env.REACT_APP_LOGIN_URL!}
            suspenseFallback={<MainLogo img={cdn('/img/isa/logo.svg')} />}
            />
    );
}

const UNPROTECTED_ROUTES = ["auth/signup"];

export default App;

