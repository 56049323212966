import { AppRouteProps } from "@vdt/component";
import React from "react";
import { LoginRoutes } from "./views/login/LoginRoutes";
const AuthContainer = React.lazy(() => import("./containers/AuthContainer"));
const LogoutContainer = React.lazy(
    () => import("./views/logout/containers/LogoutContainer")
);
export const AuthRoutes: Array<AppRouteProps> = [
    {
        path: "auth",
        title: "Auth.title",
        element: <AuthContainer />,
        children: [...LoginRoutes],
    },
    {
        path: "logout",
        title: "Auth.Logout",
        element: <LogoutContainer />,
    },
];
