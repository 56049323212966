import { AppRouteProps } from '@vdt/component'
import React from 'react'
import { CampagneDetailsRoutes } from 'views/details/CampagneDetailsRoutes'
import { CampagneListRoutes } from 'views/list/CampagneListRoutes'

const CampagneContainer = React.lazy(() => import('./containers/CampagneContainer'))

export const CampagneRoutes = [
    {
        path: 'campagne',
        title: 'Vdt.Campagne.Title',
        element: <CampagneContainer/>,
        children: [
            ...CampagneListRoutes,
            ...CampagneDetailsRoutes
        ]
    },
] as Array<AppRouteProps>
