import { AppRouteProps } from "@vdt/component";
import React from "react";
import { CampagneCreateRoutes } from "./views/create/CampagneCreateRoutes";

const CampagneContainer = React.lazy(() => import("./container/CampagneListContainer"))

export const CampagneListRoutes: Array<AppRouteProps> = [
    {
        path: "",
        element: <CampagneContainer/>,
        title: "Vdt.Campagne.List.Title",
        children: [
            ...CampagneCreateRoutes
        ]
    }
] 