import { AppRouteProps } from "@vdt/component";
import React from "react";
import { HomeRoutes } from "./home/HomeRoutes";
import { TranslationRoutes } from '@vdt/translation';
import { AuthRoutes } from '@vdt/login'
import { CampagneRoutes } from "@vdt/campagne"
import AuthLayout from "components/layout/AuthLayout";
import { SignupRoutes } from "./auth/signup/SignupRoutes";
import OrganisationAccessLayer from "container/OrganisationAccessLayer";
import { OrganisationRoutes } from "./auth/organisation/OrganisationRoutes";
import { Outlet } from "react-router-dom";

const MainLayout = React.lazy(() => import("components/layout/MainLayout"));

const authRoutes = AuthRoutes.map((route) => {
    if (route.path === "auth") {
        route.children?.push(...SignupRoutes);
        route.children?.push(...OrganisationRoutes);
    }

    return route;
});

export const AppRoutes: Array<AppRouteProps> = [
    {
        path: ":lang",
        title: `Bo.title`,
        element: <Outlet />,
        children: [
            {
                path: "",
                element: <OrganisationAccessLayer />,
                children: [
                    {
                        path: "",
                        element: <MainLayout />,
                        children: [
                            ...HomeRoutes,
                            ...TranslationRoutes,
                            ...CampagneRoutes,
                        ],
                    },
                ],
            },
            {
                path: "",
                element: <AuthLayout />,
                children: [...authRoutes],
            },
        ],
    },
];